<!-- header -->
<div class="main-section" [ngClass]="{'with-bg' : isFinish}">
  <div class="bg-container">
    <div class="overlay animate__animated animate__fadeIn"></div>
    <app-header-content></app-header-content>
  </div>

  <div class="video-container" >
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-1g-10 col-12">
          <!-- preload="auto"  -->
          <video class="w-100 video"
                [muted]="true"
                [autoplay]="true"
                (ended)="videoEnd()"
                oncanplay="this.play()"
                onloadedmetadata="this.muted = true"
                playsinline>
            <source src="./assets/images/hero-area-laptop-intro.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>

  <app-innovative-companies></app-innovative-companies>
</div>
<!-- features -->
<app-features></app-features>
<!-- ready to improve -->
<app-improve></app-improve>
<!-- pricing -->
<app-pricing></app-pricing>
<!-- client say -->
<app-clients-say></app-clients-say>
