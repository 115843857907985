<footer >
    <div class="main-footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 col-12">
            <a  href="/home" class="img-container">
              <img src="./assets/images/Connect-Logo.png">
            </a>
          </div>
          <div class="col-lg-9 col-12">
            <div class="dataLoading h-100 d-flex align-items-center justify-content-center" *ngIf='isloaded'>
                <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>
            <ng-container *ngIf='!isloaded'>
              <ul class="social-icon" *ngIf="socialList && socialList.length > 0">
                <li *ngFor="let item of socialList">
                  <a [href]="item.url" [innerHTML]="item.svgCode | sanitizeHtml"  target="_blank">
                  </a>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-right">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 col-12">
            <p>
              {{ 'footer.Connect2021' | translate }}
              <!-- href="https://expertapps.com.sa/" target="_blank" -->
              <span>{{ 'footer.ExpertApps' | translate }}</span>
            </p>
          </div>
          <div class="col-md-5 col-12">
            <div class="term-condition">
              <a [routerLink]="['/termnsandcondition']" >  {{ 'footer.Termsconditions' | translate }}</a>
              <a [routerLink]="['/privacy']" >{{ 'footer.Privacypolicy' | translate }} </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</footer>
