import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../services/general.service';

declare let $: any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html'
})
export class HomePageComponent implements OnInit {

  isFinish: boolean = false;

  constructor(
    private router: Router,
    private service: GeneralService
    ) { }

  ngOnInit() {
    this.service.isOpened.next(false);
    if (this.router.url.includes('contactus') ) {
      $("body").addClass("contactUs-page");

    }else{
      $("body").removeClass("contactUs-page");
    }
  }

  videoEnd() {
    this.isFinish = true;
  }


}
