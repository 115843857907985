<div class="page-content terms-container">
  <div class="page-header">
    <h1> {{ 'footer.Privacypolicy' | translate }}</h1>
  </div>
  <div class="privaxy-content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="item">
            <h2>1 {{ 'Privacy.collect' | translate }}</h2>
            <p>{{ 'Privacy.weCollect' | translate }}</p>
            <p>{{ 'Privacy.asresult' | translate }}</p>
            <ul>
              <li>
                {{ 'Privacy.statistics' | translate }}
              </li>
              <li>
                {{ 'Privacy.information' | translate }}
              </li>
            </ul>
            <p> {{ 'Privacy.Wedo' | translate }}</p>
          </div>
          <div class="item">
              <h2>{{ 'Privacy.Aggregated' | translate }}</h2>
              <p>
                {{ 'Privacy.WhenYouvisit' | translate }}
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
