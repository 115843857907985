import { Component, OnInit, HostListener } from '@angular/core';
import { Menu } from 'src/app/models/lookups/Menu';
import { GeneralService } from 'src/app/services/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  language = localStorage.getItem('language') || 'en';

  isOpen = false;
  openDrop = false;

  isSticky = false;

  isloaded = false;

  innerWidth: any;

  menuItems: Menu[] = new Array();

  packageId: string;
  env = environment;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 79;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(private service: GeneralService) { }

  ngOnInit() {
    this.service.isOpened.next(false);
    this.service.isOpened.subscribe(data => {
     this.isOpen = data;
    });
    this.GetFreePackageId();
    this.GetMenu();
  }

  GetFreePackageId() {
    this.service.GetFreePackageId().subscribe(
      data => {
        this.packageId = data as string;
        console.log(this.packageId);

      }, err => {
        console.error(err);
      });
  }


  GetMenu() {
    this.isloaded = true;
    this.service.GetMenu().subscribe(
      data => {
        this.isloaded = false;
        this.menuItems = data as Menu[];
        this.menuItems.map((item) => {
          item.openDrop = false;
        });
      }, err => {
        console.error(err);
        this.isloaded = false;
      });
  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }


  @HostListener('document:click', ['$event']) click() {
    if (this.isOpen) { this.isOpen = false; }
  }
  @HostListener('click', ['$event']) onClick(event: any): void {
    event.stopPropagation();
  }
}
